import React from "react";
import { Dropdown } from "react-bootstrap";
import flag from "../../assets/images/flag/GB.png";
import F1 from "../../assets/images/flag/GB.png";
import F2 from "../../assets/images/flag/DE.png";
import F3 from "../../assets/images/flag/FR.png";
import F4 from "../../assets/images/flag/IT.png";
import F5 from "../../assets/images/flag/RU.png";
import { Link } from "react-router-dom";
import logo from "../../assets/images/OptimaLogo.png";

function AuthHeader(props) {
  const { onModalOpen } = props;
  return (
    <div className="container-xxl">
      <div className="row align-items-center">
        <div className="col">
          <Link
            to={process.env.PUBLIC_URL + "/"}
            className="d-flex align-items-center"
          >
            <img src={logo} alt="Logo" height="40" />
          </Link>
        </div>
        <div className="col-auto">
          <div className="d-flex align-item-center">
            <Dropdown className="dropdown zindex-popover py-2 px-3">
              <Dropdown.Toggle
                as="s"
                className="nav-link dropdown-toggle pulse"
                href="#!"
                role="button"
                data-bs-toggle="dropdown"
              >
                <img src={flag} alt="" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-md-end m-0">
                <div className="card border-0 ">
                  <ul className="list-unstyled py-2 px-3">
                    <li>
                      <a href="#!" className="">
                        <img src={F1} alt="" /> English
                      </a>
                    </li>
                    <li>
                      <a href="#!" className="">
                        <img src={F2} alt="" /> German
                      </a>
                    </li>
                    <li>
                      <a href="#!" className="">
                        <img src={F3} alt="" /> French
                      </a>
                    </li>
                    <li>
                      <a href="#!" className="">
                        <img src={F4} alt="" /> Italian
                      </a>
                    </li>
                    <li>
                      <a href="#!" className="">
                        <img src={F5} alt="" /> Russian
                      </a>
                    </li>
                  </ul>
                </div>
              </Dropdown.Menu>
            </Dropdown>
            <div className="setting ms-2 mt-1">
              <a
                href="#!"
                data-bs-toggle="modal"
                data-bs-target="#Settingmodal"
                onClick={onModalOpen}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="30px"
                  height="30px"
                  viewBox="0 0 38 38"
                >
                  <path
                    d="M19,28c-4.964,0-9-4.04-9-9c0-4.964,4.036-9,9-9c4.96,0,9,4.036,9,9  C28,23.96,23.96,28,19,28z M19,16c-1.654,0-3,1.346-3,3s1.346,3,3,3s3-1.346,3-3S20.654,16,19,16z"
                    style={{ fill: "var(--primary-color)" }}
                    data-st="fill:var(--chart-color4);"
                  ></path>
                  <path
                    className="st0"
                    d="M19,24c-2.757,0-5-2.243-5-5s2.243-5,5-5s5,2.243,5,5S21.757,24,19,24z M19,16c-1.654,0-3,1.346-3,3  s1.346,3,3,3s3-1.346,3-3S20.654,16,19,16z M32,19c0-1.408-0.232-2.763-0.648-4.034l3.737-1.548l-0.766-1.848l-3.743,1.551  c-1.25-2.452-3.251-4.452-5.702-5.701l1.551-3.744l-1.848-0.765l-1.548,3.737C21.762,6.232,20.409,6,19,6  c-1.409,0-2.756,0.248-4.026,0.668l-1.556-3.756L11.57,3.677l2.316,5.592C15.416,8.462,17.154,8,19,8c6.065,0,11,4.935,11,11  s-4.935,11-11,11S8,25.065,8,19c0-3.031,1.232-5.779,3.222-7.771L9.808,9.816c-0.962,0.963-1.764,2.082-2.388,3.306l-3.744-1.551  l-0.765,1.847l3.738,1.548C6.232,16.238,6,17.592,6,19c0,1.409,0.232,2.763,0.648,4.034l-3.737,1.548l0.766,1.848l3.744-1.551  c1.25,2.451,3.25,4.451,5.701,5.7l-1.551,3.744l1.848,0.766l1.548-3.737C16.237,31.768,17.591,32,19,32s2.762-0.232,4.033-0.648  l1.549,3.737l1.848-0.766l-1.552-3.743c2.451-1.25,4.451-3.25,5.701-5.701l3.744,1.551l0.765-1.848l-3.736-1.548  C31.768,21.763,32,20.409,32,19z"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AuthHeader;
