import React from 'react';

const FAQSection = () => {
    const faqItems = [
        {
            id: '1',
            question: 'Who can invest in Optima Baskets?',
            answer: (
                <>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam consectetur sit amet ante nec vulputate. Nulla aliquam, justo auctor consequat tincidunt, arcu erat mattis lorem.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam consectetur sit amet ante nec vulputate.</p>
                </>
            )
        },
        {
            id: '2',
            question: 'Who can invest in Optima Baskets?',
            answer: 'Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute. non cupidatat skateboard dolor brunch. Foosd truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt alqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim ke ffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.'
        },
        {
            id: '3',
            question: 'Who can invest in Optima Baskets?',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptas expedita, repellendus est nemo cum quibusdam optio, voluptate hic a tempora facere, nihil non itaque alias similique quas quam odit consequatur.'
        },
        {
            id: '4',
            question: 'Who can invest in Optima Baskets?',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptas expedita, repellendus est nemo cum quibusdam optio, voluptate hic a tempora facere, nihil non itaque alias similique quas quam odit consequatur.'
        },
        {
            id: '11',
            question: 'Who can invest in Optima Baskets?',
            answer: (
                <>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam consectetur sit amet ante nec vulputate. Nulla aliquam, justo auctor consequat tincidunt, arcu erat mattis lorem.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam consectetur sit amet ante nec vulputate.</p>
                </>
            )
        },
        {
            id: '22',
            question: 'Who can invest in Optima Baskets?',
            answer: 'Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute. non cupidatat skateboard dolor brunch. Foosd truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt alqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim ke ffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.'
        },
        {
            id: '33',
            question: 'Who can invest in Optima Baskets?',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptas expedita, repellendus est nemo cum quibusdam optio, voluptate hic a tempora facere, nihil non itaque alias similique quas quam odit consequatur.'
        },
        {
            id: '44',
            question: 'Who can invest in Optima Baskets?',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptas expedita, repellendus est nemo cum quibusdam optio, voluptate hic a tempora facere, nihil non itaque alias similique quas quam odit consequatur.'
        },
    ];

    return (
        <section className="faq section light-blue">
            <div className="container light-blue">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <h2 className="wow fadeInUp" data-wow-delay=".4s">How can we help you?</h2>
                        </div>
                    </div>
                </div>
                <div className="row g-3">
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="accordion" id="accordionExample">
                            {faqItems.slice(0, 4).map((item) => (
                                <div className="accordion-item" key={item.id}>
                                    <h2 className="accordion-header" id={`heading${item.id}`}>
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target={`#collapse${item.id}`} aria-expanded="false" aria-controls={`collapse${item.id}`}>
                                            <span className="title">{item.question}</span><i className="fa fa-plus"></i>
                                        </button>
                                    </h2>
                                    <div id={`collapse${item.id}`} className="accordion-collapse collapse" aria-labelledby={`heading${item.id}`}
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            {item.answer}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="accordion" id="accordionExample2">
                            {faqItems.slice(4).map((item) => (
                                <div className="accordion-item" key={item.id}>
                                    <h2 className="accordion-header" id={`heading${item.id}`}>
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target={`#collapse${item.id}`} aria-expanded="false" aria-controls={`collapse${item.id}`}>
                                            <span className="title">{item.question}</span><i className="fa fa-plus"></i>
                                        </button>
                                    </h2>
                                    <div id={`collapse${item.id}`} className="accordion-collapse collapse" aria-labelledby={`heading${item.id}`}
                                        data-bs-parent="#accordionExample2">
                                        <div className="accordion-body">
                                            {item.answer}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FAQSection;