import React from "react";
import InstantExchangeIcon from "../../../assets/images/landingpage/feature-icon-1.png";
import SafeSecureIcon from "../../../assets/images/landingpage/feature-icon-2.png";
import InstantTradingIcon from "../../../assets/images/landingpage/feature-icon-3.png";

const Feature = () => {
  const features = [
    {
      icon: SafeSecureIcon,
      title: "Secured",
      description:
        "Fully backed by the underlying constituents which are held with an insured and regulated custodian",
      description2: "Daily proof of reserves",
      delay: "0.4s",
    },
    {
      icon: InstantExchangeIcon,
      title: "DeFi Integration",
      description: "Basket tokens are freely tradeable on secondary markets",
      description2:
        "Could be used as collateral for leverage on deFi lending protocols",
      delay: "0.2s",
    },
    {
      icon: InstantTradingIcon,
      title: "Regulated",
      description: "Baskets are complaint with EU laws",
      description2:
        "Minting and redeeming is allowed for any KYCed individuals or KYBed entity",
      delay: "0.6s",
    },
  ];

  return (
    <div className="feature section light-blue">
      <div id="feature" style={{ scrollMarginTop: "100px" }}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h2 className="wow fadeInUp" data-wow-delay=".4s">
                  One Platform, All the Power
                </h2>
                <p className="wow fadeInUp mt-2" data-wow-delay=".6s">
                  <div style={{ marginBottom: 1 }}>
                    <i>
                      <b>Optima Baskets cover the whole value chain!</b>
                    </i>
                  </div>
                  Proprietary construction of thematic, smart beta and risk
                  adjusted strategy baskets with automated monthly
                  reconciliation and rebalancing.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {features.map((feature, index) => (
              <div
                key={index}
                className="col-lg-4 col-md-6 col-12 wow fadeInUp"
                data-wow-delay={feature.delay}
                style={{ textAlign: "left" }}
              >
                <div
                  className="feature-box"
                  style={{ height: "100%", minHeight: "300px" }}
                >
                  <div className="tumb">
                    <img src={feature.icon} alt={feature.title} />
                  </div>
                  <h4 className="text-title">{feature.title}</h4>
                  <p style={{ textAlign: "left" }}>{feature.description}</p>
                  <p style={{ textAlign: "left" }}>{feature.description2}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
