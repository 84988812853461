import React from 'react';

const StartProcess = () => {
    const steps = [
        {
            serial: "01",
            title: "Onboarding",
            description: "During the onboarding process, we will ask you to provide some personal information and identification documents.",
            description2: " You can check how the data will be handled in our \"privacy policy\""
        },
        {
            serial: "02",
            title: "Register",
            description: "Once the onboarding process is finalized, you will be guided to set-up your account using your email.",
            description2: "For better security we recommend setting up 2FA from start."
        },
        {
            serial: "03",
            title: "Start Trading",
            description: "You are now ready to mint your first Optima basket!",
            description2: "At launch, the platform only accepts stable coins as initial payments so make sure you transfer USDC into your Optima account first."
        }
    ];

    return (
        <section className="start-process section light-blue">
            <div className="container light-blue">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <h2 className="wow fadeInUp" data-wow-delay=".4s">Ready to Get Started?</h2>
                            <p className="wow fadeInUp" data-wow-delay=".6s">
                                As Optima Baskets are EU regulatory complaint, we are taking our onboarding process very seriously in order to protect both our platforms and our users. We are excited to have you as a part of Optima community and look forward to helping you getting a safe access to diversified investments.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {steps.map((step, index) => (
                        <div key={index} className="col-lg-4 col-md-4 col-12">
                            <div className="single-process">
                                <span className="serial">{step.serial}</span>
                                <h3>{step.title}</h3>
                                <p>{step.description}
                                    <br/>
                                    <br/>
                                    {step.description2}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default StartProcess;