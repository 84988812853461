import React from "react";
import logo from "../../../assets/images/OptimaLogo.png";

const Header = () => {
  return (
    <header className="header navbar-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <div className="nav-inner">
              <nav className="navbar navbar-expand-lg">
                <a
                  className="navbar-brand fw-bold fs-3"
                  href="index.html"
                  title="Logo"
                  style={{ marginRight: "25px" }}
                >
                  <img
                    src={logo}
                    alt="logo"
                    width="100"
                    style={{ borderRadius: "10px" }}
                  />
                </a>
                <button
                  className="navbar-toggler mobile-menu-btn"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="toggler-icon"></span>
                  <span className="toggler-icon"></span>
                  <span className="toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse sub-menu-bar"
                  id="navbarSupportedContent"
                >
                  <ul
                    id="nav"
                    className="navbar-nav ms-auto"
                    style={{ width: "auto" }}
                  >
                    <li className="nav-item">
                      <a href="#heroarea" className="active">
                        Home
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#feature">Features</a>
                    </li>
                    <li className="nav-item">
                      <a href="#market">Baskets</a>
                    </li>
                    {/*<li className="nav-item">
                      <a href="#blog">Insights</a>
                    </li>*/}
                    <li className="nav-item">
                      <a href="#team">About</a>
                    </li>
                    {/*<li className="nav-item">
                                            <a
                                                className="dd-menu collapsed"
                                                href="javascript:void(0)"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#submenu-1-1"
                                                aria-controls="navbarSupportedContent"
                                                aria-expanded="false"
                                                aria-label="Toggle navigation"
                                            >
                                                Pages
                                            </a>
                                            <ul className="sub-menu collapse" id="submenu-1-1">
                                                <li className="nav-item">
                                                    <a href="https://pixelwibes.com/template/cryptoon/html/dist/ui-elements/auth-signin.html">
                                                        Sign In
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="https://pixelwibes.com/template/cryptoon/html/dist/ui-elements/auth-signup.html">
                                                        Sign Up
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="https://pixelwibes.com/template/cryptoon/html/dist/ui-elements/auth-password-reset.html">
                                                        Reset Password
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="https://pixelwibes.com/template/cryptoon/html/dist/ui-elements/auth-404.html">
                                                        404 Error
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>*/}
                    <li className="nav-item" style={{ marginLeft: "20px" }}>
                      <a href="/sign-in">
                        Login
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="/sign-up"
                        className="btn bg-light text-primary py-2 px-3 rounded-pill"
                      >
                        Register
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
