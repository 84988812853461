import { useState, useEffect } from 'react';
import axios from 'axios';

const useBasketData = () => {
    const [baskets, setBaskets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBasketData = async () => {
            try {

                const response = await axios.get('https://optima-api-dev-int.trilix.io/baskets/returns');
                
                const basketData = response.data.basket_returns || [];

                console.log(basketData);
                
                setBaskets(basketData);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchBasketData();
    }, []);

    return { baskets, loading, error };
};

export default useBasketData;
