import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function ConfirmationPopup({ show, onHide, onConfirm, title, body }) {
    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{body}</Modal.Body>
            <Modal.Footer>
                <Button className='' variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button style={{ background: "#FFBA32", border: 'none' }} variant="primary" onClick={onConfirm}>
                    Proceed
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ConfirmationPopup;