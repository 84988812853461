import React from "react";
import heroImage from "../../../assets/images/landingpage/main-image.png";
import heroShape from "../../../assets/images/landingpage/hero-shape.svg";
import Banner from "../../../Component/Banner/Banner";
import useBasketData from "../../../Hooks/useBasket";

const HeroArea2 = () => {
  const { baskets, loading, error } = useBasketData();

  return (
    <section className="hero-area light-blue" id="heroarea">
      <img className="hero-shape" src={heroShape} alt="#" />
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-12 col-12">
            <div className="hero-content" style={{ textAlign: "center" }}>
              <h1 className="wow fadeInUp" data-wow-delay=".4s">
                The Golden Standard for Tokenized Investment Strategies
              </h1>
              <p className="wow fadeInUp" data-wow-delay=".6s">
                <b>
                  <i>Diversify your portfolio with Optima baskets!</i>
                </b>
                <br />
                <br />
                The only investment baskets that are chain agnostic, regulatory
                complaint, fully backed by their underlying assets, freely
                tradeable and integrated with DeFi protocols.
              </p>
              <div
                className="tradingview-widget-container"
                style={{ borderRadius: "10px", overflow: "hidden" }}
              >
                <Banner data={baskets} />
                {/*<iframe
                                    title="TradingView Widget"
                                    src="https://s.tradingview.com/embed-widget/ticker-tape/?locale=en#%7B%22symbols%22%3A%5B%7B%22description%22%3A%22Doge%22%2C%22proName%22%3A%22HUOBI%3ADOGUSDT%22%7D%2C%7B%22description%22%3A%22Ada%22%2C%22proName%22%3A%22BINANCE%3AADAUSDT%22%7D%2C%7B%22description%22%3A%22Solana%22%2C%22proName%22%3A%22BINANCE%3ASOLUSDT%22%7D%2C%7B%22description%22%3A%22Shiba%22%2C%22proName%22%3A%22BINANCE%3ASHIBUSDT%22%7D%2C%7B%22description%22%3A%22Bnb%22%2C%22proName%22%3A%22BINANCE%3ABNBUSDT%22%7D%2C%7B%22description%22%3A%22Eth%22%2C%22proName%22%3A%22BITSTAMP%3AETHUSD%22%7D%2C%7B%22description%22%3A%22Btc%22%2C%22proName%22%3A%22BINANCE%3ABTCUSDT%22%7D%2C%7B%22description%22%3A%22Dot%22%2C%22proName%22%3A%22BINANCE%3ADOTUSDT%22%7D%5D%2C%22showSymbolLogo%22%3Afalse%2C%22colorTheme%22%3A%22light%22%2C%22isTransparent%22%3Afalse%2C%22displayMode%22%3A%22adaptive%22%2C%22width%22%3A%22100%25%22%2C%22height%22%3A46%2C%22utm_source%22%3A%22www.example.com%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22ticker-tape%22%7D"
                                    style={{ boxSizing: 'border-box', height: '74px', width: '100%' }}
                                />*/}
              </div>
              <div
                className="button wow fadeInUp"
                data-wow-delay=".8s"
                style={{ textAlign: "left" }}
              >
                <a
                  href="https://www.pixelwibes.com/template/cryptoon/html/dist/index.html"
                  className="btn bg-light text-primary"
                >
                  Start Trading
                </a>
              </div>
            </div>
          </div>
          <div
            className="col-lg-7 col-12"
            style={{
              marginTop: "0px",
              paddingTop: "0px",
              marginBottom: "auto",
            }}
          >
            <img
              className="hero-image"
              src={heroImage}
              alt="hero image"
              width="780"
              height="536"
              style={{
                width: "580px",
                height: "450px",
                objectFit: "cover",
                marginLeft: "28px",
                borderRadius: "20px",
                marginTop: "0px",
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroArea2;
