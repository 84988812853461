import React, { useState } from "react";

const ContactSection = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    queryType: "Tread Help",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log(formData);
  };

  return (
    <section className="contactus section light-blue" id="contact">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-title"
            style={{padding: "0 0px",
              textAlign: "left"
            }}>
              <h2 className="wow fadeInUp" data-wow-delay=".4s"
              style={{textAlign: "center"}}>
                We'd Love to Hear From You
              </h2>
              <p className="wow fadeInUp" data-wow-delay=".6s">
                We welcome any feedback, questions, or ideas. Your input and
                collaboration are crucial as we aim to meet the diverse needs of
                various blockchain ecosystems and drive innovation in the crypto
                asset management industry.
              </p>
              <p className="wow fadeInUp" data-wow-delay=".6s">
                Let's work together to advance adoption and development in this
                exciting field. Reach out to us:
                <br />
                <b>- Users</b> interested in learning more about Optima and
                getting started.
                <br />
                <b>- Protocol Developers</b> or supporters of L1 or L2
                blockchains who want to integrate Optima Basket Tokens into your
                ecosystem.
                <br />
                <b>- Fintech, Crypto Exchanges, or CeFi Providers</b> looking to
                offer Optima Basket Tokens to your clients.
                <br />
                <b>- Users</b> interested in providing Optima strategies to your
                clients.
                <br />
              </p>
            </div>
          </div>
        </div>
        <form className="row g-2" onSubmit={handleSubmit}>
          <div className="col-md-4 col-sm-6">
            <div className="form-floating">
              <input
                type="text"
                className="form-control"
                id="floatingInputname"
                placeholder="Your Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
              <label htmlFor="floatingInputname">Your Name</label>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="form-floating">
              <input
                type="email"
                className="form-control"
                placeholder="Your Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              <label>Your Email</label>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="form-floating">
              <select
                className="form-select"
                id="floatingSelect"
                aria-label="Floating label select example"
                name="queryType"
                value={formData.queryType}
                onChange={handleChange}
              >
                <option value="Product question">
                  I have questions about any of your products
                </option>
                <option value="Partnership opportunity">
                  I would like to discuss a partnership opportunity
                </option>
                <option value="Other question">
                  I have another type of request
                </option>
              </select>
              <label>Reason</label>
            </div>
          </div>
          <div className="col-12">
            <div className="form-floating">
              <textarea
                className="form-control"
                placeholder="Message (Optional)"
                style={{ height: "160px" }}
                name="message"
                value={formData.message}
                onChange={handleChange}
              ></textarea>
              <label>Message (Optional)</label>
            </div>
          </div>
          <div className="col-12 mt-3 button">
            <button type="submit" className="btn">
              Submit
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ContactSection;
