import React from "react";
import avatar1 from "../../../assets/images/landingpage/team/delia.jpeg";
import avatar2 from "../../../assets/images/landingpage/team/michal.jpeg";
import avatar3 from "../../../assets/images/landingpage/team/cristian.jpg";

const Team = () => {
  const teamMembers = [
    {
      name: "Delia Sabau",
      image: avatar1,
      delay: "0.3s",
    },
    {
      name: "Michal Bacia",
      image: avatar2,
      delay: "0.5s",
    },
    {
      name: "Cristian Richarte",
      image: avatar3,
      delay: "0.7s",
    },
  ];

  return (
    <section className="team section light-blue" id="team">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-title">
              <h2 className="wow fadeInUp" data-wow-delay=".4s">
                We are building the future of crypto investments
              </h2>
              <p className="wow fadeInUp" data-wow-delay=".6s">
                Optima delivers istitutional-grade investment products to the
                digital asset market, led by a team of experts with deep
                experience in both traditional finance and DeFi. Our team is
                uniquely positioned to incorporate best practices such as
                investor protections, regulatory compliance, transparent
                reporting, and robust product structuring, while also leveraging
                the composability and innovation of decentralized finance.
              </p>
            </div>
          </div>
        </div>
        <div className="row g-3">
          {teamMembers.map((member, index) => (
            <div
              key={index}
              className="col-lg-4 col-md-6 col-12 wow fadeInUp "
              data-wow-delay={member.delay}
            >
              <div className="card img-effect2 light-blue"
              style={{border: "0px"}}>
                <div className="team-block">
                  <img
                    src={member.image}
                    alt={member.name}
                    style={{
                      height: "350px",
                      width: "350px",
                      objectFit: "cover",
                      margin: "32px auto",
                      display: "block",
                      borderRadius: "8px",
                    }}
                  />
                  <div className="team-content flex-column">
                    <h6 className="team-caption fw-bold">{member.name}</h6>
                    <div className="social">
                      <a href="#" className="px-1">
                        <span className="fa fa-linkedin"></span>
                      </a>
                      <a href="#" className="px-1">
                        <span className="fa fa-twitter"></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Team;
