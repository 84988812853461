// ChangeCell.jsx
import React from 'react';
import { getChangeColors } from './colorUtils';

const ChangeCell = ({ value }) => {
    const { backgroundColor, textColor } = getChangeColors(parseFloat(value));

    return (
        <div style={{
            backgroundColor,
            color: 'black',
            width: '90%',
            borderRadius: '2px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: '8px 10px',
        }}>
            {value}
        </div>
    );
};

export default ChangeCell;