import { tns } from 'tiny-slider/src/tiny-slider';
import GLightbox from 'glightbox';

export function initializeAnimations() {
    // Sticky navbar
    window.onscroll = function () {
        if (typeof window.libScriptsLoaded === 'undefined') {
            // If not loaded, wait and try again
            setTimeout(initializeAnimations, 100);
            return;
        }

        var header_navbar = document.querySelector(".navbar-area");
        var sticky = header_navbar.offsetTop;

        var logo = document.querySelector('.navbar-brand img')
        if (window.pageYOffset > sticky) {
            header_navbar.classList.add("sticky");
        } else {
            header_navbar.classList.remove("sticky");
        }

        // show or hide the back-top-top button
        var backToTo = document.querySelector(".scroll-top");
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            backToTo.style.display = "flex";
        } else {
            backToTo.style.display = "none";
        }
    };

    // Mobile menu button
    let navbarToggler = document.querySelector(".mobile-menu-btn");
    if (navbarToggler) {
        navbarToggler.addEventListener('click', function () {
            navbarToggler.classList.toggle("active");
        });
    }

    // Road Map slider
    if (document.querySelector('.road-map-slider')) {
        tns({
            container: '.road-map-slider',
            items: 1,
            slideBy: 'page',
            autoplay: false,
            mouseDrag: true,
            gutter: 0,
            nav: true,
            controls: false,
            responsive: {
                0: { items: 2 },
                540: { items: 3 },
                768: { items: 4 },
                992: { items: 4 },
                1170: { items: 6 }
            }
        });
    }

    // Testimonial slider
    if (document.querySelector('.testimonial-slider')) {
        tns({
            container: '.testimonial-slider',
            items: 3,
            slideBy: 'page',
            autoplay: false,
            mouseDrag: true,
            gutter: 0,
            nav: true,
            controls: false,
            responsive: {
                0: { items: 1 },
                540: { items: 1 },
                768: { items: 2 },
                992: { items: 2 },
                1170: { items: 3 }
            }
        });
    }

    // GLightbox
    GLightbox({
        'href': 'https://www.youtube.com/watch?v=EZyAJ79PTZM',
        'type': 'video',
        'source': 'youtube',
        'width': 900,
        'autoplayVideos': true,
    });
}