import React, { useEffect, useState, useRef } from 'react';
import './Banner.css';

const Banner = ({ data }) => {
  const [itemCount, setItemCount] = useState(data.length);
  const contentRef = useRef(null);

  // Clone enough items to fill the screen width plus some buffer
  const items = [...data, ...data, ...data];

  useEffect(() => {
    if (contentRef.current) {
      document.documentElement.style.setProperty('--item-count', data.length);
    }
  }, [data]);

  const getChangeClass = (changeValue) => {
    // Remove any non-numeric characters except decimal point and minus sign
    const numericValue = parseFloat(changeValue.replace(/[^\d.-]/g, ''));
    
    if (numericValue > 0) return 'positive';
    if (numericValue < 0) return 'negative';
    return 'neutral';
  };

  return (
    <div className="banner-wrapper">
      <div 
        ref={contentRef}
        className="banner-content"
        style={{
          animation: `scroll ${data.length * 3}s linear infinite`
        }}
      >
        {items.map((item, index) => (
          <div className="banner-tile" key={index}>
            <div className="tile-name">{item.basket_symbol}</div>
            <div className="tile-amount">{item.current_value}</div>
            <div className={`tile-change ${getChangeClass(item.return_7_day_pct)}`}>
              {item.return_7_day_pct}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Banner;