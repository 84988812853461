import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import ConfirmationPopup from '../Ui Component/ConfirmationPopup'; // Make sure this path is correct

function Spot() {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [confirmationDetails, setConfirmationDetails] = useState({ action: '', type: '' });

    const handleShowConfirmation = (action, type) => {
        setConfirmationDetails({ action, type });
        setShowConfirmation(true);
    };

    const handleCloseConfirmation = () => {
        setShowConfirmation(false);
    };

    const handleConfirm = () => {
        // Implement your buy/sell logic here
        console.log(`Confirmed: ${confirmationDetails.action} ${confirmationDetails.type}`);
        setShowConfirmation(false);
    };

    return (
        <div className="card mb-3">
            <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                <h6 className="mb-0 fw-bold ">Spot</h6>
            </div>
            <div className="card-body">
                <div className="row g-3">
                    <div className="col-lg-6">
                        <div className="d-flex align-items-center justify-content-between my-3">
                            <span className="small text-muted">Avbl</span>
                            <span className="">310.800000 USDT</span>
                        </div>
                        <form>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Price</span>
                                <input type="text" className="form-control" onChange={() => { }} />
                                <span className="input-group-text">USDT</span>
                            </div>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Amount</span>
                                <input type="text" className="form-control" onChange={() => { }} />
                                <span className="input-group-text">BTC</span>
                            </div>
                            <div className="input-group mb-3">
                                <div className="mb-2 d-flex justify-content-between align-items-center w-100">
                                    <span className="text-muted">0%</span>
                                    <span className="text-muted px-2">25%</span>
                                    <span className="text-muted px-1">50%</span>
                                    <span className="text-muted px-1">75%</span>
                                    <span className="text-muted">100%</span>
                                </div>
                                <Form.Range />
                            </div>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Total</span>
                                <input type="text" className="form-control" onChange={() => { }} />
                                <span className="input-group-text">USDT</span>
                            </div>
                            <button type="button" onClick={() => handleShowConfirmation('BUY', 'BTC')} className="btn flex-fill btn-light-success py-2 fs-5 text-uppercase px-5 w-100">BUY BTC</button>
                        </form>
                    </div>
                    <div className="col-lg-6">
                        <div className="d-flex align-items-center justify-content-between my-3">
                            <span className="small text-muted">Avbl</span>
                            <span className="">0.0000000 BTC</span>
                        </div>
                        <form>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Price</span>
                                <input type="text" className="form-control" onChange={() => { }} />
                                <span className="input-group-text">USDT</span>
                            </div>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Amount</span>
                                <input type="text" className="form-control" onChange={() => { }} />
                                <span className="input-group-text">BTC</span>
                            </div>
                            <div className="input-group mb-3">
                                <div className="mb-2 d-flex justify-content-between align-items-center w-100">
                                    <span className="text-muted">0%</span>
                                    <span className="text-muted px-2">25%</span>
                                    <span className="text-muted px-1">50%</span>
                                    <span className="text-muted px-1">75%</span>
                                    <span className="text-muted">100%</span>
                                </div>
                                <Form.Range />
                            </div>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Total</span>
                                <input type="text" className="form-control" onChange={() => { }} />
                                <span className="input-group-text">USDT</span>
                            </div>
                            <button type="button" onClick={() => handleShowConfirmation('SELL', 'BTC')} className="btn flex-fill btn-light-danger py-2 fs-5 text-uppercase px-5 w-100">SELL BTC</button>
                        </form>
                    </div>
                </div>
            </div>

            <ConfirmationPopup
                show={showConfirmation}
                onHide={handleCloseConfirmation}
                onConfirm={handleConfirm}
                title={`Confirm ${confirmationDetails.action} ${confirmationDetails.type}`}
                body={`Are you sure you want to ${confirmationDetails.action} ${confirmationDetails.type}?`}
            />
        </div>
    )
}

export default Spot;