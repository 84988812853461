// colorUtils.js

const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
};

const getContrastTextColor = (backgroundColor) => {
    const rgb = hexToRgb(backgroundColor);
    if (!rgb) return '#000000';
    
    const luminance = (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255;
    return luminance > 0.5 ? '#000000' : '#FFFFFF';
};

const interpolateColor = (percentage, maxPercentage = 30) => {
    // Normalize the percentage to a 0-1 scale based on maxPercentage
    const normalizedValue = Math.min(Math.abs(percentage), maxPercentage) / maxPercentage;
    
    if (percentage > 0) {
        // White (255,255,255) to Green (0,200,0)
        const r = Math.round(255 - (255 - 0) * normalizedValue);
        const g = Math.round(255 - (255 - 200) * normalizedValue);
        const b = Math.round(255 - (255 - 0) * normalizedValue);
        return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
    } else if (percentage < 0) {
        // White (255,255,255) to Red (255,0,0)
        const r = Math.round(255);
        const g = Math.round(255 - (255) * normalizedValue);
        const b = Math.round(255 - (255) * normalizedValue);
        return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
    }
    
    return '#FFFFFF'; // Return white for 0
};

export const getChangeColors = (value) => {
    const percentage = parseFloat(value);
    
    if (isNaN(percentage)) {
        return { backgroundColor: '#FFFFFF', textColor: '#000000' };
    }

    const backgroundColor = interpolateColor(percentage);
    const textColor = getContrastTextColor(backgroundColor);
    
    return { backgroundColor, textColor };
};