import React from 'react'

const Chevron = () => {
    return (
        <a href="#" class="scroll-top">
            <i class="fa fa-chevron-up"></i>
        </a>
    )
}

export default Chevron