import React from "react";
import Changelogo from '../../assets/images/change-log.svg';

function Changelogdetail() {
    return (
        <>
            <div className="col-12">
                <div className="card">
                    <div className="card-body text-center p-5">
                        <img src={Changelogo} className="img-fluid mx-size" alt="No Data" />
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-12 mt-5">
                <div className="card">
                    <div className="card-body">
                         <div className="pt-2">
                            <h6 className="d-inline-block"><span className="badge bg-warning font-weight-light">v1.1.0</span></h6>
                            <span className="text-muted">&nbsp;&nbsp;&nbsp;–- Nov 21, 2023</span>
                            <ul className="ms-5">
                                <li>Update react 18.1.0 to react 18.2.0 </li>
                                <li>Update bootstrap 5.1.3 to bootstrap 5.3.2</li>
                                <li>Update react-bootstrap 2.4.0 to react-bootstrap 2.9.1</li>
                                <li>Update react-router-dom 6.2.2 to react-router-dom 6.14.1</li>
                                <li>Update react-dom 18.1.0 to react-dom 18.2.0</li>
                                <li>Update react-redux 8.0.2 to react-redux 8.1.2</li>
                                <li>Update react-apexcharts 1.4.0 to react-apexcharts 1.4.1</li>
                                <li>Update nodescss 7.0.1 to node-sass 9.0.0</li>
                                <li>Dark Mode Color Contrast BugFixing</li>
                            </ul>
                        </div>
                        <div className="pt-2">
                            <h6 className="d-inline-block"><span className="badge bg-warning font-weight-light">v1.0.0</span></h6>
                            <span className="text-muted">&nbsp;&nbsp;&nbsp;–- Sep 14, 2022</span>
                            <ul className="ms-5">
                                <li>Initial release ofcryptoon! Lots more coming soon though 😁</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Changelogdetail;