import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

// project css file
import "../../assets/css/LandingPage/animate.css";
import "../../assets/css/LandingPage/glightbox.min.css";
import "../../assets/css/LandingPage/tiny-slider.css";
import "../../assets/css/cryptoon.style.min.css";
import libscriptsBundle from "./libscripts.bundle";

// Onepage css file
import "../../assets/css/LandingPage/custom.style.min.css";
import Header from "./Components/Header";
import Feature from "./Components/Feature";
import MarketCapitalization from "./Components/MarketCapitalization";
import StartProcess from "./Components/StartProcess";
import Team from "./Components/Team";
import IntroVideo from "./Components/IntroVideo";
import Testimonials from "./Components/Testimonials";
import RoadMap from "./Components/RoadMap";
import BlogSection from "./Components/BlogSection";
import FAQSection from "./Components/FAQSection";
import ContactSection from "./Components/ContactSection";
import Footer from "./Components/Footerv2";
import Chevron from "./Components/Chevron";
import HeroArea from "./Components/HeroSection";
import HeroArea2 from "./Components/HeroSection2";

// Last main desing changes
const LandingPage = () => {
  <Helmet>
    <script src={libscriptsBundle} type="text/javascript" />
  </Helmet>;

  return (
    <div>
      <div id="mainDiv" className="page-three theme-blue">
        <Header />
        <HeroArea2 />
        <Feature />
        <MarketCapitalization />
        <StartProcess />
        {/* <IntroVideo /> */}
        {/* <Testimonials /> */}
        {/* <RoadMap /> */}
        {/* <BlogSection /> */}
        <ContactSection />
        <FAQSection />
        <Team />
        <Footer />
        <Chevron />
      </div>
    </div>
  );
};

export default LandingPage;
